function LinkList(props) {
  const getElement = (element) => {
    if (element.type && element.type === "youtube") {
      return (
        <iframe
          width="560"
          height="315"
          src={element.url}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="max-w-full"
        ></iframe>
      );
    } else {
      return (
        <a href={element.url ? element.url : element}>
          {element.title ? element.title : element}
        </a>
      );
    }
  };

  return (
    <div>
      <ul className="mt-4 mb-6">
        {props.elements.map((element, id) => {
          return (
            <li key={id} className="mt-2">
              {getElement(element)}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default LinkList;
