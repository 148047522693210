function Education(props) {
  const hasDissertation = () => {
    if (props.degree.dissertation) {
      return <p>Dissertation: "{props.degree.dissertation}"</p>;
    }
  };
  return (
    <div className="mb-6">
      <h4 className="text-lg font-bold mt-4 ">{props.degree.degree}</h4>
      <p>Concentration: {props.degree.specialization}</p>
      {hasDissertation()}
    </div>
  );
}

export default Education;
