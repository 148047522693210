import PageTitle from "../components/PageTitle";
import Project from "../components/Project";
import { forwardRef } from "react";

const Portfolio = forwardRef((props, ref) => {
  const projects = [
    {
      title: "Camp OI2: Creating Online Learning Experiences ",
      description:
        "My staff and I developed a resource site in the Canvas LMS for faculty who are new to teaching online. The following are pages from a module that I developed that focused on creating and organizing an online course within Canvas.",
      roles: ["Content Authoring", "Web Design", "Learning Management Systems"],
      images: [
        {
          url: "images/campoi2_1.png",
          title: "Page 1",
          type: "image",
        },
        {
          url: "images/campoi2_2.png",
          title: "Page 2",
          type: "image",
        },
        {
          url: "images/campoi2_3.png",
          title: "Page 3",
          type: "image",
        },
        {
          url: "images/campoi2_4.png",
          title: "Page 4",
          type: "image",
        },
        {
          url: "images/campoi2_5.png",
          title: "Page 5",
          type: "image",
        },
        {
          url: "images/campoi2_6.png",
          title: "Page 6",
          type: "image",
        },
        {
          url: "images/campoi2_7.png",
          title: "Page 7",
          type: "image",
        },
        {
          url: "images/campoi2_8.png",
          title: "Page 8",
          type: "image",
        },
        {
          url: "images/campoi2_9.png",
          title: "Page 9",
          type: "image",
        },
        {
          url: "images/campoi2_10.png",
          title: "Page 10",
          type: "image",
        },
        {
          url: "images/campoi2_11.png",
          title: "Page 11",
          type: "image",
        },
        {
          url: "images/campoi2_12.png",
          title: "Page 12",
          type: "image",
        },
        {
          url: "images/campoi2_13.png",
          title: "Page 13",
          type: "image",
        },
        {
          url: "images/campoi2_14.png",
          title: "Page 14",
          type: "image",
        },
        {
          url: "images/campoi2_15.png",
          title: "Page 15",
          type: "image",
        },
        {
          url: "images/campoi2_16.png",
          title: "Page 16",
          type: "image",
        },
        {
          url: "images/campoi2_17.png",
          title: "Page 17",
          type: "image",
        },
        {
          url: "images/campoi2_18.png",
          title: "Page 18",
          type: "image",
        },
      ],
    },
    {
      title: "Teaching Texas Slavery",
      description:
        "This project was created for faculty clients at The University of Texas at Austin. The site features historical content, an instructional framework, and an overview of key concepts to support teaching about the history of slavery in Texas. High resolution historical documents are used throughout as primary sources to be used in student learning.",
      roles: [
        "UI/UX",
        "Web Development",
        "Graphic Design",
        "Project Management",
      ],
      media: [
        {
          url: "https://www.youtube.com/embed/q_mhsH-DWnE",
          title: "Demo",
          type: "youtube",
        },
      ],
      links: [
        {
          url: "https://teachingtxslavery.education.utexas.edu/",
          title: "Teaching Texas Slavery",
        },
      ],
    },
    {
      title: "Understanding Digital Badges: Metadata",
      description:
        "A section from a larger project designed to provide an overview of digital microcredentials, I wrote and assembled this module on metadata using Articulate Rise. This work was used in support of a new university-wide badge initiative. I developed the initial metadata standard for digital badges at UT-Austin.",
      roles: ["Multimedia Authoring", "Content Development"],
      media: [
        {
          url: "https://www.youtube.com/embed/nEUkmogZYEE",
          title: "Demo",
          type: "youtube",
        },
      ],
    },
    {
      title: "Web Application for the Texas I-CAN project",
      description:
        "I build this application for a reseach project that features a large database of academically-integration physical activity lessons. The application supports teachers in the classroom by providing a filterable list of activities based on grade, unit, subject, and type of activity. Once selected, an activity can be added to a schedule of activities that teachers can maintain. The application also allows teachers to submit their own custom activities.",
      roles: ["UI/UX", "Web Development", "Graphic Design"],
      media: [
        {
          url: "https://www.youtube.com/embed/ko7OKGH3I0s",
          title: "Demo",
          type: "youtube",
        },
      ],
    },
    {
      title: "Graphic Design",
      description:
        "As a volunteer Director of Technology at my church, I created both print and screen media to promote church events and sermons and communicate information to the congregation. The following are a selection of slides that I created to be projected during worship services.",
      roles: ["Graphic Design", "Motion Graphics", "3D modeling"],
      images: [
        {
          url: "images/23.png",
          title: "23",
          type: "image",
        },
        {
          url: "images/AlmostChristian.png",
          title: "Almost Christian",
          type: "image",
        },
        {
          url: "images/ChristianSurvivalGuide.png",
          title: "Christian Survival Guide",
          type: "image",
        },
        {
          url: "images/GodIsBigEnough.png",
          title: "God Is Big Enough",
          type: "image",
        },
        {
          url: "images/HallOfFame.png",
          title: "Hall of Fame",
          type: "image",
        },
        {
          url: "images/HeavenHelpUs.png",
          title: "Heaven Help Us",
          type: "image",
        },
        {
          url: "images/Launch.png",
          title: "Launch",
          type: "image",
        },
        {
          url: "images/PalmSunday.png",
          title: "Palm Sunday",
          type: "image",
        },
        {
          url: "images/StoryOfUs.png",
          title: "The Story of Us",
          type: "image",
        },
        {
          url: "images/WhenPigsFly.png",
          title: "When Pigs Fly",
          type: "image",
        },
        {
          url: "images/WontYouBeMyNeighbor.png",
          title: "Won't You Be My Neighbor",
          type: "image",
        },
      ],
    },
    {
      title: "Alien Rescue",
      description:
        "I spent 10 years as project lead on <i>Alien Rescue</i>, a problem-based learning game for sixth grade space science. Two unique characteristics of the project are that 1) it serves simultaneously as a research platform and as an educational product that is in use worldwide, and 2) the project itself serves as a learning context for graduate learning technologies students. The project has provided key opportunities for me to mentor students on a variety of skills, in areas such as instructional design, project management, game development, 3D modeling, graphic design, video production and editing, and research skills.",
      roles: [
        "Project leadership and strategy",
        "Instructional design",
        "Mentor student designers and developers",
        "Web, game, and multimedia design and development",
        "Marketing and promotion",
        "Design and deliver professional development to K-12 teachers",
        "Conduct classroom research",
        "Present and publish on research",
      ],
      links: [
        {
          url: "https://alienrescue.edb.utexas.edu/",
          title: "Alien Rescue: Official Site",
        },
      ],
      recognition: [
        "2012 AECT Interactive Learning Award",
        "2013 AECT Outstanding Practice Award",
      ],
    },
    {
      title: "Web Development Tutorials",
      description:
        "I offered a series of instructor-led tutorials to graduate Learning Technologies students covering the basics of HTML, CSS, and JavaScript. The media below illustrate the completed result of each tutorial.",
      roles: [
        "Instructional design",
        "Classroom instruction",
        "Web design and development",
      ],
      links: [
        {
          url: "https://codepen.io/lucas/pen/BjxogX/",
          title: "HTML",
        },
        {
          url: "http://codepen.io/lucas/pen/ZQZvey/",
          title: "CSS",
        },
        {
          url: "https://codepen.io/lucas/pen/bprMqV/",
          title: "Bootstrap",
        },
        {
          url: "http://codepen.io/lucas/pen/mPPzWe/",
          title: "JavaScript",
        },
      ],
    },
    {
      title: "Virtual Patients",
      description:
        "This application was developed for my client, MD Anderson Cancer Center, to provide an online continuing medical education offering for general practitioners related to tobacco cessation counseling. Through this work, we helped the client envision the potential of virtual patient interactions and worked with subject matter experts to author an experience that situated practicing physicians within authentic scenarios. We emphasized the need for repeated practice and frequent feedback, created an interface that allowed users to respond to patients at key points, and integrated feedback that was triggered by users' choices and based on MD Anderson's clinical guidelines.",
      roles: [
        "Project leadership and strategy",
        "Instructional design",
        "Simulation development",
        "3D graphic design",
        "Client relationship management",
      ],
      media: [
        {
          url: "https://www.youtube.com/embed/Hr4PZRruZvY",
          title: "Demo",
          type: "youtube",
        },
      ],
    },
    {
      title: "Cancer Survivorship Case Studies",
      description:
        "This interactive web application wasPageTitle the user experience, we adopted the design and branding guidelines established by MD Anderson's marketing department to deliver an experience that reinforced and promoted their brand.",
      roles: [
        "Project leadership and strategy",
        "Instructional design",
        "Front-end web development",
        "Client relationship management",
      ],
      media: [
        {
          url: "https://www.youtube.com/embed/mQB8b2bK6Io",
          title: "Demo",
          type: "youtube",
        },
      ],
    },
  ];

  return (
    <div ref={ref} className="bg-gray-50 py-10">
      <div className="container m-auto px-10 mt-10">
        <PageTitle title="Portfolio" />
        {projects.map((project, id) => {
          return <Project key={id} project={project} />;
        })}
      </div>
    </div>
  );
});

export default Portfolio;
