import { useState, useEffect } from "react";
import ReactDOM from "react-dom";

function ImageGallery(props) {
  const [showOverlay, setShowOverlay] = useState(false);
  const [activeUrl, setActiveUrl] = useState(null);
  const [activeAlt, setActiveAlt] = useState(null);
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        if (showOverlay) {
          setShowOverlay(false);
        }
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [showOverlay]);

  const toggleOverlay = (event) => {
    setShowOverlay(!showOverlay);
    setActiveUrl(event.target.currentSrc);
    setActiveAlt(event.target.alt);
  };

  const getOverlay = () => {
    if (!showOverlay) return null;

    return ReactDOM.createPortal(
      <div
        className="bg-black/90 backdrop-grayscale fixed inset-0 h-screen w-screen flex justify-center items-center cursor-pointer "
        onClick={toggleOverlay}
      >
        <img
          src={activeUrl}
          className="max-w-screen max-h-screen shadow-md py-12 px-12"
          alt={activeAlt}
        />
      </div>,
      document.body
    );
  };

  return (
    <div>
      {getOverlay()}
      <ul className="mt-4 mb-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        {props.elements.map((element, id) => {
          return (
            <li key={id} className="mt-2">
              <figure className="mr-6 my-3 border-1 border-white">
                <img
                  className="cursor-pointer"
                  src={element.url}
                  alt={element.title}
                  onClick={toggleOverlay}
                />
                <figcaption className="mt-2 italic">{element.title}</figcaption>
              </figure>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default ImageGallery;
