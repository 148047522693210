function UnorderedList(props) {
  return (
    <ul className="mt-4 ml-8 mb-6 list-disc list-outside">
      {props.elements.map((element, id) => {
        return (
          <li key={id} className="mt-2">
            {element}
          </li>
        );
      })}
    </ul>
  );
}

export default UnorderedList;
