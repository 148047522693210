import Experience from "../components/Experience";
import Education from "../components/Education";
import PageTitle from "../components/PageTitle";
import PageSubtitle from "../components/PageSubtitle";
import TagList from "../components/TagList";
import { forwardRef } from "react";

const Resume = forwardRef((props, ref) => {
  const experiences = [
    {
      id: 1,
      title: "Director of Instructional Innovation",
      org: "The University of Texas at Austin  - College of Education",
      start: "2014",
      end: "present",
      accomplishments: [
        "Provided expert instructional design support and consultation for university courses and academic programs, encompassing the design of individual learning experiences as well as the successful launch of entire online and hybrid graduate degree programs. Promoted continuous improvement through program evaluation and revision.",
        "Designed and facilitated impactful professional development courses, webinars, workshops, and online learning modules for educators, leveraging appropriate adult learning principles.",
        "Spearheaded the college’s emergency teaching strategy during the COVID-19 pandemic, ensuring seamless transition to online delivery while upholding rigorous academic standards.",
        "Managed, coached, and prioritized the efforts of a high-performance learning and development team consisting of instructional designers, web developers, and graduate research assistants, delivering responsive and proficient support and professional development to over 200 faculty members in course design and delivery, educational technology integration, and custom learning solutions.",
        "Administered the college LMS solution. Developed robust web applications utilizing full-stack JavaScript (React, Express, and Next.js), LTI, and Canvas LMS to enhance teaching and learning experiences.",
        "Applied expert knowledge of multimedia and eLearning authoring tools, such as Articulate 360 and Adobe Creative Cloud, to create high-quality graphics, audio, video, animations, and interactive elements.",
      ],
    },
    {
      id: 2,
      title: "Independent Consultant",
      org: "Multiple Contracts",
      start: "2006",
      end: "present",
      accomplishments: [
        "Delivered instructional design, web development, audio and video production, and multimedia authoring services to clients in higher education, healthcare, and private industry.",
        "Collaborated closely with project stakeholders to effectively translate business and instructional needs into impactful instructional solutions.",
        "Successfully addressed a diverse range of project deliverables, including immersive learning simulations, learning games, interactive experiences, presentations, and full-stack web applications.",
        "Skillfully administered LMS platforms on behalf of clients.",
      ],
    },
    {
      id: 3,
      title: "Project Manager",
      org: "The University of Texas at Austin - Center for Teaching and Learning",
      start: "2010",
      end: "2014",
      accomplishments: [
        "Successfully led and managed a course redesign effort in an introductory Biology course, resulting in recognition and awards. Collaborated with subject matter experts to ensure project success. Demonstrated expert client and project management skills throughout the process. Adapted and pivoted effectively to accommodate changing needs and requirements.",
        "Provided strategic input for the center during organizational changes",
        "Led and mentored a team of developers and instructional design professionals",
        "Oversaw infrastructure needs and managed procurement, product selection, and vendor engagement for a next-generation eLearning delivery infrastructure",
        "Played a key role in the launch of a high school-to-college program by overseeing the program's digital learning strategy. Coordinated relationships with vendors and ensured deliverables were met. Engaged with potential partners to encourage program adoption",
      ],
    },
    {
      id: 4,
      title: "Systems Analyst",
      org: "The University of Texas at Austin - Division of Instructional Innovation and Assessment",
      start: "2006",
      end: "2010",
      accomplishments: [
        "Inaugurated a widely used and successful program that facilitated the use of multimedia technologies by students and faculty in university courses. I presented on the initiative to state and national audiences and received substantial interest from other institutions seeking to replicate the program.",
        "Produced audio, video, and interactive web resources to support innovative instruction in university courses.",
        "Applied for and was awarded a grant to develop a series of multimedia modules on critical thinking. I planned and managed the development of the modules, served as lead instructional designer, and delivered the project on time and on budget.",
        "Designed and initiated a popular summer internship program that provided in-depth multimedia training and project experience for undergraduate students.",
        "Frequent guest lecturer in university courses on instructional design and various multimedia tools and processes, including video production and editing, web development, Photoshop, and 3D modeling.",
      ],
    },
  ];

  const degrees = [
    {
      id: 1,
      degree: "Ph.D., Curriculum and Instruction",
      specialization: "Learning Technologies",
      institution: "The University of Texas at Austin",
      dissertation:
        "The effects of problem-based learning scaffolds on cognitive load, problem- solving, and student performance within a multimedia enhanced learning environment",
    },
    {
      id: 2,
      degree: "M.A., Curriculum and Instruction",
      specialization: "Learning Technologies",
      institution: "The University of Texas at Austin",
    },
    {
      id: 3,
      degree: "B.S., Kinesiology",
      specialization: "Health Promotion and Fitness",
      institution: "The University of Texas at Austin",
    },
  ];

  const skills = [
    "Instructional Design",
    "ADDIE",
    "SAM",
    "Learning Theory",
    "Project Management",
    "People Management",
    "Public Speaking",
    "Adobe Photoshop",
    "Adobe Illustrator",
    "Adobe InDesign",
    "Adobe AfterEffects",
    "Adobe Premiere",
    "Adobe Audition",
    "Davinci Resolve",
    "Blender",
    "Articulate Storyline",
    "Articulate Rise",
    "Articulate Review",
    "Adobe Captivate",
    "Vyond",
    "Canvas LMS",
    "SCORM",
    "LTI",
    "HTML",
    "CSS",
    "JavaScript",
    "TypeScript",
    "React",
    "Three.js",
    "Web Accessibility",
  ];

  return (
    <div ref={ref} className="py-10">
      <div className="container m-auto px-10 mt-10">
        <PageTitle title="Resume" />
        <PageSubtitle title="Profile" />
        <p>
          A highly accomplished and versatile professional, adept in various
          disciplines such as educational technology, instructional design, and
          multimedia development. Possessing extensive expertise in learning and
          development, adult learning principles, and project management.
          Demonstrating an impressive track record of over two decades in
          instructional design and innovation environments. Proven ability to
          lead collaborative teams and provide expert consultation, while
          successfully guiding major instructional design ventures, technical
          development endeavors, and training and professional development
          initiatives.
        </p>

        <PageSubtitle title="Experience" />
        {experiences.map((experience) => {
          return <Experience key={experience.id} experience={experience} />;
        })}

        <PageSubtitle title="Education" />
        {degrees.map((degree) => {
          return <Education key={degree.id} degree={degree} />;
        })}
        <PageSubtitle title="Key Skills" />
        <TagList elements={skills} />
      </div>
    </div>
  );
});

export default Resume;
