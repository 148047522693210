function TagList(props) {
  return (
    <ul className="mt-4 mb-6 flex flex-wrap">
      {props.elements.map((element, id) => {
        return (
          <li
            key={id}
            className="m-2 px-4 py-1 text-center rounded-full bg-blue-600 text-white"
          >
            {element}
          </li>
        );
      })}
    </ul>
  );
}

export default TagList;
