import PageSubtitle from "./PageSubtitle";
import UnorderedList from "./UnorderedList";
import LinkList from "./LinkList";
import ImageGallery from "./ImageGallery";
function Project(props) {
  function createDescriptionMarkup() {
    return { __html: props.project.description };
  }

  function getLinks() {
    if (props.project.links) {
      return (
        <div>
          <h4 className="text-lg font-bold mt-4 ">Links</h4>
          <LinkList elements={props.project.links} />
        </div>
      );
    }
  }
  function getMedia() {
    if (props.project.media) {
      return (
        <div>
          <h4 className="text-lg font-bold mt-4 ">Media</h4>
          <LinkList elements={props.project.media} />
        </div>
      );
    }
  }

  function getImages() {
    if (props.project.images) {
      return (
        <div>
          <h4 className="text-lg font-bold mt-4 ">Images</h4>
          <ImageGallery elements={props.project.images} />
        </div>
      );
    }
  }
  return (
    <div>
      <PageSubtitle title={props.project.title} />
      <p dangerouslySetInnerHTML={createDescriptionMarkup()} />
      <h4 className="text-lg font-bold mt-4 ">Roles</h4>
      <UnorderedList elements={props.project.roles} />
      {getLinks()}
      {getMedia()}
      {getImages()}
    </div>
  );
}
export default Project;
