import "./App.css";
import Portfolio from "./sections/Portfolio";
import Resume from "./sections/Resume";
import { useRef, useState } from "react";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  window.addEventListener("resize", (event) => {
    onResize();
  });

  const onResize = (event) => {
    setViewportHeight(window.innerHeight);
    console.log(viewportHeight);
  };

  const resumeRef = useRef(null);
  const portfolioRef = useRef(null);
  const onResumeClick = () =>
    resumeRef.current.scrollIntoView({ behavior: "smooth" });

  const onPortfolioClick = () =>
    portfolioRef.current.scrollIntoView({ behavior: "smooth" });

  // const scrollTo = (ref) => {
  //   ref.current.scrollIntoView({ behavior: "smooth" });
  // };

  const inlineBlock = {
    display: "inline-block",
  };

  return (
    <div className="subpixel-antialiased pb-20">
      <ScrollToTop viewportHeight={viewportHeight} />
      <div className="container m-auto px-5 mt-10">
        <div className="h-screen align-middle flex flex-col items-center justify-center">
          <h1 className="text-6xl text-center font-bold">
            Lucas Horton
            <br />
            <subtitle className="text-3xl font-light">
              <span style={inlineBlock}>Instructional Designer</span> +{" "}
              <span style={inlineBlock}>Multimedia Developer</span> + Leader
            </subtitle>
          </h1>
          <p className="mt-10 text-2xl text-center font-light">
            I'm an instructional design and interactive multimedia leader with a
            passion for developing elegant and effective learning solutions.
            I've enjoyed over 20-years working within higher education settings
            while also consulting with organizations within healthcare, higher
            education, government agencies, and private industry.
          </p>
          <p className="mt-10 text-2xl text-center font-bold">
            <button className="grow text-center" onClick={onResumeClick}>
              Resume
            </button>
            &nbsp;|&nbsp;
            <button className="grow text-center" onClick={onPortfolioClick}>
              Portfolio
            </button>
          </p>
        </div>
      </div>
      <Portfolio ref={portfolioRef} />
      <Resume ref={resumeRef} />
    </div>
  );
};

export default App;
