import UnorderedList from "./UnorderedList";

function Experience(props) {
  const hasExperience = () => {
    if (props.experience) {
      return <p>{props.experience.org}</p>;
    }
  };
  return (
    <div>
      <h4 className="text-lg font-bold mt-4">{props.experience.title}</h4>
      {hasExperience()}
      <p>
        {props.experience.start} - {props.experience.end}{" "}
      </p>
      <UnorderedList elements={props.experience.accomplishments} />
    </div>
  );
}

export default Experience;
