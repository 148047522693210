import { useState, useEffect } from "react";

const ScrollToTop = (props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  if (scrollPosition >= props.viewportHeight / 2) {
    return (
      <div className="fixed bottom-0 text-right w-full opacity-70">
        <button className="bg-black text-white p-4 m-4" onClick={scrollToTop}>
          Back To Top
        </button>
      </div>
    );
  }
};

export default ScrollToTop;
